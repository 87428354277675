<template>
    <div class="h-100 w-100">
        <l-map
          ref="map"
          style="height: 100%; width: 100%; z-index: 1"
          :zoom="initialZoom"
          :center="initialCenter"
          @update:zoom="zoomUpdated"
          @update:center="centerUpdated"
          @update:bounds="boundsUpdated"
          :options="{ zoomControl: false }"
        >
            <l-tile-layer
              v-for="tileProvider in tileProviders"
              :key="tileProvider.name"
              :name="tileProvider.name"
              :visible="tileProvider.visible"
              :url="tileProvider.url"
              :attribution="tileProvider.attribution"
              layer-type="base"
            />
            <l-tile-layer
              v-for="overlayProvider in overlayProviders"
              :key="overlayProvider.name"
              :name="overlayProvider.name"
              :url="overlayProvider.url"
              :visible="overlayProvider.visible"
              layer-type="overlay"
            />
            <l-control-layers :z-index="1000" position="topright"></l-control-layers>

            <v-overlay :z-index="10000" :value="overlay_loading" :opacity="0.2">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
            </v-overlay>
            <mygeo @result_searching="result_searching"/>
            <l-choropleth-layer
              v-if="
          data_geo.features.length != 0 &&
            data_geo.features[0].geometry.type == 'MultiPolygon' &&
            config_selected.type_geo == 'choropleth'
        "
              :data="data_array"
              idKey="id"
              :value="changeValue"
              :geojson="data_geo"
              geojsonIdKey="id"
              :colorScale="changeColorScale"
              titleKey="key_name"
            >
                <template slot-scope="props">
                    <l-info-control
                      light
                      :item="props.currentItem"
                      :unit="props.unit"
                      title=""
                      placeholder="Placez-vous sur une zone"
                    />
                    <l-reference-chart
                      v-if="config_selected.page != 'PLU'"
                      :title="key_name"
                      :colorScale="changeColorScale"
                      :min="roundUpDown(props.min)"
                      :max="roundUpDown(props.max, 'up')"
                      position="topleft"
                    />
                </template>
            </l-choropleth-layer>

            <!-- ***CREATE ZONES ON MAP*** -->
            <l-layer-group ref="zones">
                <l-geojson
                  v-if="
            data_geo.features.length != 0 &&
              config_selected.type_geo == 'geojson_zone'
          "
                  :geojson="new_radius(data_geo)"
                  :options="options"
                  :options-style="styleZoneFunction"
                ></l-geojson>
            </l-layer-group>
            <l-layer-group ref="zones_radius">
                <l-geojson
                  v-if="
            data_geo.features.length != 0 &&
              config_selected.type_geo == 'geojson_zone'
          "
                  :geojson="new_radius(data_geo, 300)"
                  :options="options_etendu"
                  :options-style="styleZoneRadiusFunction"
                  :visible="visible_radius_boolean"
                ></l-geojson>
            </l-layer-group>
            <l-layer-group ref="zones_radius">
                <l-geojson
                  v-if="
            data_geo.features.length != 0 &&
              config_selected.type_geo == 'geojson_zone'
          "
                  :geojson="new_radius(data_geo, 500)"
                  :options="options_etendu"
                  :options-style="styleZoneRadius500Function"
                  :visible="visible_radius_boolean"
                ></l-geojson>
            </l-layer-group>

            <l-layer-group ref="markerCluster">
                <l-geojson
                  v-if="
            data_geo.features.length != 0 && config_selected.type_geo == 'point'
          "
                  :geojson="data_geo"
                  :visible="false"
                ></l-geojson>
            </l-layer-group>

            <l-layer-group ref="markerCluster">
                <l-geojson
                  v-if="
            data_geo.features.length != 0 &&
              config_selected.type_geo == 'multi_geo'
          "
                  :geojson="data_geo"
                  :options="options"
                  :options-style="styleZoneFunction"
                  :visible="false"
                ></l-geojson>
            </l-layer-group>

            <l-control position="topleft" v-if="config_selected.show_filter == true">
                <v-menu
                  v-model="filter_checked"
                  :close-on-content-click="false"
                  :close-on-click="false"
                  offset-x
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          x-large
                          color="#be1a3e"
                          class="white--text"
                        >
                            Filtrer
                        </v-btn>
                    </template>
                    <v-card class="hud_overlay_filtre">
                        <filtre-transports
                          v-if="config_selected.page == 'transports'"
                          @filter_component="filter_selected = $event"
                        />
                        <filtre-point-interet
                          v-if="config_selected.page == 'points_interets'"
                          @filter_component="filter_selected = $event"
                        />
                        <filtre-zone-tva v-if="config_selected.page == 'zone_tva'"/>
                        <filtre-operation-amen
                          v-if="config_selected.page == 'operation_amen'"
                          @filter_component="filter_selected = $event"
                        />
                        <filtre-commerces v-if="config_selected.page == 'commerce'"/>
                        <filtre-permis-construire
                          v-if="config_selected.page == 'permis_construire'"
                        />
						<filtre-transactions-geolocalisees
							v-if="config_selected.page == 'marche_ancien_geoloc' || config_selected.page == 'marche_neuf_geoloc'"
						/>
                    </v-card>
                </v-menu>
            </l-control>
            <l-control position="topright">
                <v-card class="hud_overlay_legend">
                    <legend-zone-tva v-if="config_selected.page == 'zone_tva'"/>
                    <legend-zone-peb v-if="config_selected.page == 'zone_peb'"/>
                    <legend-operation-amen
                      v-if="config_selected.page == 'operation_amen'"
                    />
                    <legend-scolaire v-if="config_selected.page == 'etablissements_scolaires'"/>
                    <legend-vigilance v-if="config_selected.page == 'points_vigilance'"/>
                </v-card>
            </l-control>

            <l-control-zoom position="bottomright"></l-control-zoom>
        </l-map>
    </div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'; // Re-uses images from ~leaflet package
import L from 'leaflet';
import 'leaflet-defaulticon-compatibility';

import {GeoApiFrProvider} from 'leaflet-geosearch';
import mygeo from '@/components/geosearch/mygeo.vue';

import {
    LMap,
    LTileLayer,
    LControlLayers,
    LMarker,
    LGeoJson,
    LPopup,
    LControl,
    LLayerGroup,
    LControlZoom
    // LWMSTileLayer
} from 'vue2-leaflet';
import Vue2LeafletMarkercluster from 'vue2-leaflet-markercluster';
import Choropleth from './vue-choropleth/ChoroplethLayer';
import InfoControl from './vue-choropleth/InfoControl';
import ReferenceChart from './vue-choropleth/ReferenceChart';
import FiltreZoneTva from '../components/Filters/FiltreZoneTva';
import FiltreCommerces from '../components/Filters/FiltreCommerces';
import FiltreTransports from '../components/Filters/FiltreTransports';
import FiltrePointInteret from '../components/Filters/FiltrePointInteret';
import FiltreOperationAmen from '../components/Filters/FiltreOperationAmen';
import FiltrePermisConstruire from '../components/Filters/FiltrePermisConstruire';
import LegendZoneTva from '../components/Legends/LegendZoneTva';
import LegendOperationAmen from '../components/Legends/LegendOperationAmen';
import LegendScolaire from '../components/Legends/LegendScolaire';
import LegendVigilance from '../components/Legends/LegendVigilance';
import LegendZonePeb from '../components/Legends/LegendZonePeb';
import FiltreTransactionsGeolocalisees from "@/components/Filters/FiltreTransactionsGeolocalisees.vue";

import {mapGetters} from 'vuex';

import * as geojson_style from '../assets/style/geojson_style';
import * as big_filter from './Filters/big_filter';

export default {
    name: 'Map',
    components: {
        LMap,
        LTileLayer,
        LControlLayers,
        'l-choropleth-layer': Choropleth,
        'l-layer-group': LLayerGroup,
        'l-info-control': InfoControl,
        'l-reference-chart': ReferenceChart,
        'l-geojson': LGeoJson,
        'l-control': LControl,
        'l-control-zoom': LControlZoom,
        FiltreZoneTva,
        FiltreCommerces,
        FiltreTransports,
        FiltrePointInteret,
        FiltreOperationAmen,
        FiltrePermisConstruire,
        LegendZoneTva,
        LegendZonePeb,
        LegendOperationAmen,
        LegendVigilance,
        mygeo,
        LegendScolaire,
		FiltreTransactionsGeolocalisees
    },
    data()
    {
        const initialZoom = 6;
        const initialCenter = [47.41322, -1.219482];
        return {
            url: 'https://a.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png',
            tileProviders: [
                {
                    name: 'Carte',
                    attribution: 'CartoCDN' /* 15/16600/11274 */,
                    visible: true,
                    url: 'https://a.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png'
                },
                {
                    name: 'Carte - Mode nuit',
                    attribution: 'CartoCDN',
                    visible: false,
                    url: 'https://a.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}.png'
                },
                {
                    name: 'Satellite',
                    visible: false,
                    attribution: 'ArcGIS Online',
                    url:
                      'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                }
            ],
            overlayProviders: [
                {
                    name: 'Noms de rues / villes',
                    attribution: 'CartoCDN',
                    visible: true,
                    url:
                      'https://a.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png'
                },
                {
                    name: 'Limites administratives',
                    url:
                      'https://data.geopf.fr/wmts?' +
                      '&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&TILEMATRIXSET=PM' +
                      '&LAYER=LIMITES_ADMINISTRATIVES_EXPRESS.LATEST&STYLE=normal&FORMAT=image/png' +
                      '&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}',
                    attribution: 'IGN-F/Geoportail',
                    visible: false
                },
                {
                    name: 'Parcelles cadastres',
                    url:
                      'https://data.geopf.fr/wmts?' +
                      '&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&TILEMATRIXSET=PM' +
                      '&LAYER=CADASTRALPARCELS.PARCELS&STYLE=bdparcellaire&FORMAT=image/png' +
                      '&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}',
                    attribution: 'IGN-F/Geoportail',
                    visible: false
                }
            ],
            initialCenter,
            initialZoom,
            zoom: initialZoom,
            center: initialCenter,
            zoom_for_bounds_previous: null,
            searchMarker: {
                LatLng: null,
                show: false
            },
            bounds: null,
            filter_checked: 1,
            geosearchOptions: {
                provider: new GeoApiFrProvider(),
                autoClose: true,
                keepResult: false,
                style: 'bar',
                showMarker: false
            },
            selected_filter: [],
            filter_selected: [],
            filter_layer: {},
            previous_second_or_not: false,
            previous_marker: null,
            visible_radius_boolean: false
        };
    },
    mounted()
    {
        this.$refs.map.mapObject.on('geosearch/showlocation', result =>
        {
            //todo A voir pour enlever require, c'est surement à cause de webpack
            this.previous_marker != null
              ? this.$refs.map.mapObject.removeLayer(this.previous_marker)
              : null;
            result.marker
              .setIcon(
                L.icon({
                    iconUrl: require('../assets/searchMarker.png'),
                    iconSize: [64, 128],
                    iconAnchor: [31, 103]
                })
              )
              .addTo(this.$refs.map.mapObject);
            this.previous_marker = result.marker;
            this.$store.commit('mutateLocation', result.location);
        });
        let jwt = this.$cookies.get('authcookie').split('.');
        let token = JSON.parse(Buffer.from(jwt[1], 'base64').toString());
    },
    watch: {
        '$store.getters.getFilter': function (newValue, oldValue)
        {
            this.useFilter(newValue);
        },
        config_selected: function ()
        {
            //Clear des layers groups car à des moments le layer de la zone_tva ne s'efface pas
            this.clearAllLayers();
            this.newData_geo();
            this.filter_layer = {};
		},
        data_geo_length: function ()
        {
            //* MarkerCluster ne peut pas update lui-même son geojson donc je watch la taille de data_geo pour le changer manuellement
            if (
              this.config_selected.type_geo == 'point' ||
              this.config_selected.type_geo == 'multi_geo'
            )
            {
                let machin = L.geoJson(this.data_geo, {
                    onEachFeature: this.onEachFeatureFunction,
                    pointToLayer: this.pointToLayerFunction,
                    style: this.styleZoneFunction
                });
                let markers = L.markerClusterGroup({
                    showCoverageOnHover: false,
                    maxClusterRadius: this.config_selected.precise_cluster ? 0 : 80,
                    spiderfyOnMaxZoom: this.config_selected.precise_cluster
                      ? false
                      : true,
                    zoomToBoundsOnClick: this.config_selected.precise_cluster
                      ? false
                      : true
                });
                if (machin)
                {
                    this.$refs.markerCluster.mapObject.clearLayers();
                    markers.addLayer(machin, {
                        chunkedLoading: true
                    });
                    markers.on('clusterclick', result =>
                    {
                        if (this.config_selected.precise_cluster)
                        {
                            let send_properties = {
                                page: this.config_selected.page,
                                id_array: []
                            };
                            result.layer.getAllChildMarkers().forEach(element =>
                            {
                                send_properties.id_array.push(element.feature.properties.id);
                            });
                            this.$store.dispatch('actionSelected_item', send_properties);
                        }
                    });

                    this.$refs.markerCluster.mapObject.addLayer(markers);
                }
            }
            if (this.$store.getters.getFilter != 0)
            {
                this.useFilter(this.$store.getters.getFilter);
            }
        }
    },
    methods: {
        //function to round up a number to any decimal place
        roundUpDown(number, bool = 'down')
        {
            if (bool == 'up')
            {
                return (
                  Math.ceil(number / Math.pow(10, number.toString().length - 1)) *
                  Math.pow(10, number.toString().length - 1)
                );
            }
            else
            {
                return (
                  Math.floor(number / Math.pow(10, number.toString().length - 1)) *
                  Math.pow(10, number.toString().length - 1)
                );
            }
        },
        new_radius(geojson_, distance = 0)
        {
            let geo_temp = JSON.parse(JSON.stringify(geojson_));
            switch (distance)
            {
                case 0:
                    return geojson_;
                case 300:
                    geo_temp.features.forEach((element, index) =>
                    {
                        if (element.properties.tva_55 == 1)
                        {
                            geo_temp.features[index].geometry =
                              element.properties.geometry_radius_300;
                            geo_temp.features[index].properties.id =
                              element.properties.id + '_';
                        }
                    });
                    return geo_temp;
                case 500:
                    geo_temp.features.forEach((element, index) =>
                    {
                        if (
                          element.properties.tva_55 == 1 &&
                          element.properties.eligibilite == 'O' &&
                          (element.properties.type ==
                            'Conventionné Prioritaire, Arrêté du 29 avril 2015' ||
                            element.properties.type ==
                            'Conventionné Complémentaire, Arrêté du 15 janvier 2019')
                        )
                        {
                            geo_temp.features[index].geometry =
                              element.properties.geometry_radius_500;
                            geo_temp.features[index].properties.id =
                              element.properties.id + '__';
                        }
                    });
                    return geo_temp;

                default:
                    break;
            }
        },
        useFilter(newValue)
        {
            // On recharge les layers pour n'afficher que ce qui a été sélectionner

			let markers = L.markerClusterGroup({
				showCoverageOnHover: false,
				maxClusterRadius: this.config_selected.precise_cluster ? 0 : 80,
				spiderfyOnMaxZoom: this.config_selected.precise_cluster
					? false
					: true,
				zoomToBoundsOnClick: this.config_selected.precise_cluster
					? false
					: true
			});
            let lg;
            let lg_etendu;
            switch (this.config_selected.type_geo)
            {
                case 'point':
					// HERE
                    this.$refs.markerCluster.mapObject.clearLayers();
                    newValue.forEach(element =>
                    {
                        var lg = this.filter_layer[element];
                        if (lg != undefined)
                        {
                            markers.addLayer(lg, {
                                chunkedLoading: true
                            });
							markers.on('clusterclick', result =>
							{
								if (this.config_selected.precise_cluster)
								{
									let send_properties = {
										page: this.config_selected.page,
										id_array: []
									};
									result.layer.getAllChildMarkers().forEach(element =>
									{
										send_properties.id_array.push(element.feature.properties.id);
									});
									this.$store.dispatch('actionSelected_item', send_properties);
								}
							});
                            this.$refs.markerCluster.mapObject.addLayer(markers);
                        }
                    });
                    break;
                case 'geojson_zone':
                    this.$refs.zones.mapObject.clearLayers();
                    this.$refs.zones_radius.mapObject.clearLayers();

                    if (newValue.length != 0)
                    {
                        newValue.forEach(element =>
                        {
                            lg = this.filter_layer[element];
                            lg_etendu = this.filter_layer[element + '_etendu'];
                            if (lg != undefined) this.$refs.zones.mapObject.addLayer(lg);
                            if (newValue.includes('etendu'))
                            {
                                if (lg_etendu != undefined)
                                {
                                    this.$refs.zones_radius.mapObject.addLayer(lg_etendu);
                                }
                            }
                        });
                    }
                    break;
                case 'choropleth':
                    break;

                case 'multi_geo':
                    this.$refs.markerCluster.mapObject.clearLayers();
                    if (newValue.length != 0)
                    {
                        newValue.forEach(element =>
                        {
                            var lg = this.filter_layer[element];
                            if (lg != undefined)
                            {
                                markers.addLayer(lg, {
                                    chunkedLoading: true
                                });
                                this.$refs.markerCluster.mapObject.addLayer(markers);
                                /*this.$refs.markerCluster.mapObject.addLayer(
                                  this.filter_layer['Espaces verts']
                                );*/
                            }
                        });
                    }
                    break;

                default:
                    break;
            }
        },
        result_searching(e)
        {
            this.previous_marker != null
              ? this.$refs.map.mapObject.removeLayer(this.previous_marker)
              : null;
            if (e)
            {
                let search_marker = L.marker([e.y, e.x])
                  .setIcon(
                    L.icon({
                        iconUrl: require('../assets/searchMarker.png'),
                        iconSize: [64, 128],
                        iconAnchor: [31, 103]
                    })
                  )
                  .addTo(this.$refs.map.mapObject);

                let latLngs = [search_marker.getLatLng()];
                let markerBounds = L.latLngBounds(latLngs);
                this.$refs.map.mapObject.fitBounds(markerBounds);

                this.previous_marker = search_marker;
                this.$store.commit('mutateLocation', e);
            }
        },
        clearAllLayers()
        {
            this.$refs.zones.mapObject.clearLayers();
            this.$refs.zones_radius.mapObject.clearLayers();
            this.$refs.markerCluster.mapObject.clearLayers();
        },

        zoomUpdated(zoom)
        {
            //Condition pour reset Data_geo quand on passe d'un zoom à un autre quand il y a un seconde niveau//
            this.zoom_for_bounds_previous = this.zoom;
            this.zoom = zoom;
            this.previous_second_or_not = false;
            if (this.config_selected.zoom > zoom)
            {
                this.$store.commit('resetData');
                this.clearAllLayers();
            }
            else
            {
                if (
                  this.config_selected.second?.zoom <= zoom &&
                  this.config_selected.second?.active == false
                )
                {
                    this.previous_second_or_not = true;
                    this.$store.commit('mutateActivateSecond');
                }
                else
                {
                    if (
                      this.config_selected.second?.zoom > zoom &&
                      this.config_selected.second?.active == true
                    )
                    {
                        this.previous_second_or_not = true;
                        this.$store.commit('mutateActivateSecond');
                    }
                }
            }
        },
        centerUpdated(center)
        {
            this.center = center;
        },
        boundsUpdated(bounds)
        {
            let save_bounds = this.bounds;
            this.bounds = bounds;

            this.config_selected != null
              ? this.newData_geo(
                save_bounds,
                this.zoom_for_bounds_previous,
                this.zoom
              )
              : null;
        },
        newData_geo(save_bounds, zoom_avant, zoom_apres)
        {
            //zoom sert pour ST_Difference avec sequelize car la bounding box précédente overlap sur la suivante entièrement donc la recherche ne se fait pas//
            let second = {
                name: this.config_selected.second?.page,
                bounds: this.bounds,
                save_bounds: save_bounds,
                zoom: {
                    avant: zoom_avant,
                    apres: zoom_apres
                },
                previous_second: this.previous_second_or_not, //boolean pour voir si le zoom a permis de passer d'un état second à un autre
                data_geo_null: this.data_geo_length == 0 ? true : false
            };
            let main = {
                name: this.config_selected.page,
                bounds: this.bounds,
                save_bounds: save_bounds,
                zoom: {
                    avant: zoom_avant,
                    apres: zoom_apres
                },
                previous_second: this.previous_second_or_not,
                data_geo_null: this.data_geo_length == 0 ? true : false
            };
            //Condition nécessaire et peut-être à revoir pour des recherches selon le niveau du zoom
            if (this.config_selected.second?.zoom <= this.zoom)
            {
                //D'un onglet à un autre on active le second lorsque l'on est déjà en second zoom
                this.config_selected.second.active
                  ? null
                  : this.$store.commit('mutateActivateSecond');
                if (this.config_selected.second?.type_geo == 'choropleth')
                {
                    this.$store.dispatch('actionData_geo', second);
                }
                else
                {
                    this.$store.dispatch('actionGeo_zone', second);
                }
            }
            else
            {
                if (this.config_selected.zoom <= this.zoom)
                {
                    switch (this.config_selected.type_geo)
                    {
                        case 'choropleth':
                            this.$store.dispatch('actionData_geo', main);
                            break;
                        case 'geojson_zone':
                            this.$store.dispatch('actionGeo_zone', main);
                            break;
                        case 'point':
                            if (this.config_selected.page == 'commerce')
                            {
                                this.$store.dispatch('actionData_marker_api', this.center);
                            }
                            else
                            {
                                this.$store.dispatch('actionData_marker', main);
                            }
                            break;
                        case 'multi_geo':
                            this.$store.dispatch('actionData_marker', main);
                            break;
                        default:
                            break;
                    }
                }
            }
        },
        pointToLayerFunction(feature, latlng)
        {
            return big_filter.pointToLayerFunction(
              feature,
              latlng,
              this.config_selected.page
            );
        },
        onEachFeatureFunction(feature, layer)
        {
            //! Temporaire il faut ajouter une colonne "type" dans la bdd avec l'année
            let truc = feature.properties;
            if (truc.date_depot)
                feature.properties.type = feature.properties.date_depot
                  .split('-')[1]
                  .trim();
            //!---------------------------

			else if (truc.date_mutation) {
				feature.properties.type = feature.properties.date_mutation
					.split('-')[0]
					.trim();
			}

            let lg = this.filter_layer[feature.properties.type];
            if (lg === undefined)
            {
                lg = L.layerGroup();
                this.filter_layer[feature.properties.type] = lg;
            }

            let doublon = Object.values(
              this.filter_layer[feature.properties.type]._layers
            ).find(obj =>
            {
                return obj.feature.properties.id === layer.feature.properties.id;
            });

            if (doublon == undefined) lg.addLayer(layer);

            big_filter.onEachFeatureFunction(
              feature,
              layer,
              this.config_selected.page
            );
        },
        onEachFeatureFunctionEtendu(feature, layer)
        {
            let lg_etendu = this.filter_layer[feature.properties.type + '_etendu'];
            if (lg_etendu === undefined)
            {
                lg_etendu = L.layerGroup();
                this.filter_layer[feature.properties.type + '_etendu'] = lg_etendu;
            }
            let doublon_etendu = Object.values(
              this.filter_layer[feature.properties.type + '_etendu']._layers
            ).find(obj =>
            {
                return obj.feature.properties.id === layer.feature.properties.id;
            });

            if (typeof layer.feature.properties.id == 'string')
            {
                if (doublon_etendu == undefined) lg_etendu.addLayer(layer);
            }

            big_filter.onEachFeatureFunction(
              feature,
              layer,
              this.config_selected.page
            );
        },
        styleZoneFunction(feature, layer)
        {
            return geojson_style.style_filter(
              feature,
              layer,
              this.config_selected.page
            );
        },
        styleZoneRadiusFunction(feature, layer)
        {
            return geojson_style.style_filter(
              feature,
              layer,
              this.config_selected.page,
              300
            );
        },
        styleZoneRadius500Function(feature, layer)
        {
            return geojson_style.style_filter(
              feature,
              layer,
              this.config_selected.page,
              500
            );
        },
        filterFunction(feature)
        {
            return this.$store.getters.getFilter.includes(feature.properties.type)
              ? true
              : false;
        }
    },
    computed: {
        key_name()
        {
            return 'Donnée de référence :<br /> ' + this.config_selected.value.metric;
        },
        ...mapGetters({
            data_geo: 'getData_geo',
            config_selected: 'getConfig_selected',
            overlay_loading: 'getOverlay_loading'
        }),
        data_geo_length()
        {
            //* Regarder l'explication dans son watch
            return this.data_geo.features.length;
        },
        data_array: function ()
        {
            return this.$store.state.data_array;
        },
        changeValue()
        {
            return this.$store.getters.getConfig_selected.value;
        },
        changeColorScale: function ()
        {
            return this.$store.getters.getConfig_selected.colorScale;
        },
        options_zones: function ()
        {
            return {
                style: {
                    'color': '#2DD2FC',
                    'opacity': 0.8
                }
            };
        },
        options()
        {
            return {
                pointToLayer: this.pointToLayerFunction,
                onEachFeature: this.onEachFeatureFunction
                // style: this.styleZoneFunction
            };
        },
        options_etendu()
        {
            return {
                onEachFeature: this.onEachFeatureFunctionEtendu
            };
        },
        options_clusters()
        {
            return {
                showCoverageOnHover: false,
                maxClusterRadius: this.config_selected.precise_cluster ? 0 : 80,
                spiderfyOnMaxZoom: this.config_selected.precise_cluster ? false : true,
                zoomToBoundsOnClick: this.config_selected.precise_cluster ? false : true
            };
        }
    }
};
</script>
<style lang="scss">
@import '~leaflet/dist/leaflet.css';
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';

.theme--light.v-card.hud_overlay_filtre {
    background-color: rgba(255, 255, 255, 0.9);
    padding-bottom: 1em;

    .v-list-item {
        min-height: 0;
        margin-bottom: 1em;
    }
}

.theme--light.v-card.hud_overlay_legend {
    border: 3px rgba(190, 26, 62, 0.7) solid;
    background-color: rgba(255, 255, 255, 0.95);
    padding-bottom: 1em;

    .v-list-item {
        min-height: 0;
        margin-bottom: 1em;

        .square {
            width: 15px;
            height: 15px;
            border-radius: 25px;
            margin-right: 1em;
        }
    }
}

.v-application .info {
    background-color: white !important;
}

.leaflet-control-layers-base,
.leaflet-control-layers-overlays {
    text-align: left;
}

.leaflet-top.leaflet-left .info {
    margin-top: 50px;
    max-width: 250px;
    text-align: left;
}

.info.l-info-control {
    background-color: white !important;
}
</style>
